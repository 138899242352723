import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";
import { Notyf } from "notyf"; // Notyf'i import et
import "notyf/notyf.min.css"; // Notyf'in CSS dosyasını import et

export default function Form({ selectedPackage, setShowForm }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    company: "",
    phone: "",
  });

  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focusedFields, setFocusedFields] = useState({});

  // Notyf instance oluştur
  const notyf = new Notyf({
    duration: 7000, // Bildirim 3 saniye görünecek
    position: {
      x: "center",
      y: "top",
    },
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFocus = (field) => {
    setFocusedFields((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setFocusedFields((prev) => ({ ...prev, [field]: false }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isChecked) return;

    setIsSubmitting(true);

    // EmailJS entegrasyonu
    emailjs
      .sendForm(
        "service_uwxx5lr",
        "template_ko95ch2",
        e.target,
        "jsXyZrH3WZM2EFnmF"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          notyf.success(
            "Bildirimizi aldık :) Ekiplerimiz en kısa sürede tarafınıza dönüş sağlayacaktır."
          ); // Başarılı bildirim
          setIsSubmitting(false);
          setShowForm(false);
        },
        (error) => {
          console.log("FAILED...", error.text);
          notyf.error("Mesaj gönderilirken bir hata oluştu."); // Hata bildirimi
          setIsSubmitting(false);
        }
      );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20">
      <div className="bg-white p-6 rounded-lg shadow-4xl max-w-md w-full relative ">
        <div className="flex flex-col">
          <h3 className="text-2xl font-semibold text-purple-600 text-center ">
            Hemen İletişime Geç
          </h3>
          <h3 className="text-xl font-semibold text-lime-400 mb-6 mt-2 text-center">
            {selectedPackage}
          </h3>
        </div>

        <form onSubmit={handleSubmit}>
          {["name", "email", "company", "phone", "message"].map((field) => (
            <div className="mb-6 relative" key={field}>
              {field === "message" ? (
                <textarea
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  onFocus={() => handleFocus(field)}
                  onBlur={() => handleBlur(field)}
                  required
                  placeholder=" "
                  className="w-full p-3 border border-gray-300 rounded-md shadow focus:outline-none focus:ring focus:ring-purple-600 resize-none transition duration-200 ease-in-out hover:border-purple-400"
                ></textarea>
              ) : (
                <input
                  type={field === "email" ? "email" : "text"}
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  onFocus={() => handleFocus(field)}
                  onBlur={() => handleBlur(field)}
                  required={field !== "company"}
                  placeholder=" "
                  className="w-full p-3 border border-gray-300 rounded-md shadow focus:outline-none focus:ring focus:ring-purple-600 transition duration-200 ease-in-out hover:border-purple-400"
                />
              )}
              <label
                className={`absolute left-3 top-2 text-sm font-medium text-gray-700 transition-transform duration-200 transform ${
                  formData[field] || focusedFields[field]
                    ? "scale-75 -translate-y-3 left-2 text-purple-600"
                    : "origin-left pointer-events-none"
                }`}
              >
                {field === "name"
                  ? "Ad Soyad"
                  : field === "email"
                  ? "E-posta"
                  : field === "company"
                  ? "Şirket (Opsiyonel)"
                  : field === "phone"
                  ? "Telefon"
                  : "Mesaj"}
              </label>
            </div>
          ))}
          <motion.button
            type="submit"
            disabled={!isChecked}
            className={`w-full py-2 rounded-md transition ${
              isChecked
                ? "bg-purple-600 text-white hover:bg-purple-700"
                : "bg-gray-400 text-gray-200 cursor-not-allowed"
            }`}
          >
            {isSubmitting ? "Gönderiliyor..." : "Gönder"}
          </motion.button>
          <div className="flex items-center mt-4">
            <div
              onClick={() => setIsChecked(!isChecked)}
              className={`mr-2 w-6 h-6 flex items-center justify-center border-2 ${
                isChecked
                  ? "border-purple-600 bg-purple-600"
                  : "border-gray-300 bg-white"
              } rounded-lg cursor-pointer transition duration-200`}
            >
              {isChecked && <div className="w-4 h-4 bg-white rounded-full" />}
            </div>
            <label
              className="text-gray-700 text-lg"
              style={{ fontSize: "1rem" }}
            >
              Kişisel verilerimi paylaştığımı onaylıyorum.
            </label>
          </div>
        </form>
        <motion.button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 mt-2 mb-2"
          onClick={() => setShowForm(false)}
          whileHover={{ scale: 1.2 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <AiOutlineClose size={24} />
        </motion.button>
      </div>
    </div>
  );
}
