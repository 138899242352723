import React, { useState } from "react";
import { motion } from "framer-motion";
import Form from "./Form";

const PricingTable = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState("");

  const handleTeklifAl = (pkgName) => {
    setSelectedPackage(pkgName);
    setShowForm(true);
  };

  const packages = [
    {
      name: "Ekonomik Web Tasarım Paketi",
      price: "10.000₺",
      features: [
        "Küçük ve Orta Büyüklükteki İşletmeler İçin",
        "Mobil Uyumluluk",
        "5 Sayfaya Kadar Tasarım",
        "Temel SEO Ayarları",
        "1 Yıl Ücretsiz Hosting",
        "İçerik Yönetim Sistemi Entegrasyonu",
        "Haftalık Güncellemeler",
        "Temel Güvenlik Önlemleri",
        "Kullanıcı Dostu Arayüz",
        "Sosyal Medya Entegrasyonu",
        "Destek ve Bakım Hizmeti",
        "Proje Sonu Detaylı Raporlama",
      ],
    },
    {
      name: "Kurumsal Web Tasarım Paketi",
      price: "15.000₺",
      popular: true,
      features: [
        "Orta ve Büyük Ölçekli İşletmeler İçin",
        "Mobil Uyumluluk",
        "10 Sayfaya Kadar Tasarım",
        "Gelişmiş SEO Optimizasyonu",
        "2 Yıl Ücretsiz Hosting",
        "İçerik Yönetim Sistemi Entegrasyonu",
        "Haftalık Güncellemeler",
        "Gelişmiş Güvenlik Önlemleri",
        "Özel Grafik ve Animasyon Tasarımları",
        "E-ticaret Entegrasyonu",
        "Sosyal Medya Entegrasyonu",
        "24/7 Destek ve Bakım Hizmeti",
        "Aylık Raporlama",
      ],
    },
    {
      name: "Özel Yazılım Tasarım Paketi",
      price: "25.000₺",
      features: [
        "Büyük ve Prestijli Markalar İçin",
        "Mobil Uyumluluk",
        "Sınırsız Sayfa Tasarımı",
        "Profesyonel SEO Optimizasyonu",
        "Sınırsız Hosting",
        "Özel İçerik Yönetim Sistemi",
        "Haftalık ve Aylık Güncellemeler",
        "İleri Seviye Güvenlik Çözümleri",
        "Özel Tasarım ve Animasyonlar",
        "Gelişmiş E-ticaret Çözümleri",
        "Sosyal Medya ve CRM Entegrasyonu",
        "24/7 Premium Destek ve Bakım Hizmeti",
        "Aylık Detaylı Raporlama",
      ],
    },
  ];

  return (
    <div>
      <section className="relative py-12 bg-white font-display3">
        <div className="absolute top-0 left-0 w-full hidden md:block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 60"
            className="w-full"
          >
            <path
              fill="#facc15"
              d="M0,60L30,50C60,40,120,20,180,10C240,0,300,10,360,20C420,30,480,40,540,30C600,20,660,0,720,10C780,20,840,40,900,40C960,40,1020,20,1080,10C1140,0,1200,20,1260,30C1320,40,1380,50,1410,60L1440,60L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
            />
          </svg>
        </div>
        <div className="relative py-6 bg-white flex flex-col items-center justify-center text-white text-center px-4">
          {/* Main Heading */}
          <h1 className="text-5xl sm:text-8xl font-bold ">
            <span className="text-black">Web sitenizi</span>{" "}
            <span className="text-blue-400">size </span>
            <span className="text-lime-400"> özel</span>{" "}
            <span className="text-pink-500">tasarlıyoruz</span>.
          </h1>

          {/* Subtext */}
          <p className="mt-4 text-base sm:text-lg text-gray-300 mt-6">
            <span className="text-black">
              Marka kimliğinizi yansıtan, kullanıcı dostu ve estetik bir web sitesi
              sunuyoruz.
            </span>
          </p>
        </div>

        <div className="absolute top-0 left-0 w-full hidden md:block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 60"
            className="w-full"
          >
            <path
              fill="#facc15"
              d="M0,60L30,50C60,40,120,20,180,10C240,0,300,10,360,20C420,30,480,40,540,30C600,20,660,0,720,10C780,20,840,40,900,40C960,40,1020,20,1080,10C1140,0,1200,20,1260,30C1320,40,1380,50,1410,60L1440,60L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
            />
          </svg>
        </div>
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-purple-600 font-display3 mt-8 mb-5">
            Web Tasarım
          </h2>
          <p className="mt-2 text-gray-600 font-display3 mb-8">
            Profesyonel Web Tasarım Hizmeti.
          </p>
        </div>
        <div className="relative max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4 sm:px-6 lg:px-8 mb-8">
          {packages.map((pkg, index) => (
            <motion.div
              key={index}
              className={`relative p-10 rounded-lg transition-all duration-300 ease-in-out border flex flex-col justify-between ${
                pkg.popular
                  ? "border-purple-500 bg-white shadow-xl"
                  : "border-gray-200"
              }`}
              style={{
                boxShadow: pkg.popular
                  ? "0 12px 40px rgba(0, 0, 0, 0.3)"
                  : "0 8px 25px rgba(0, 0, 0, 0.2)",
              }}
              whileHover={{
                boxShadow: "0 12px 40px rgba(0, 0, 0, 0.3)",
              }}
            >
              {pkg.popular && (
                <span className="bg-purple-600 text-white text-sm px-3 py-1 rounded-full absolute top-0 -translate-y-4 left-4 z-20 shadow-2xl">
                  Popüler
                </span>
              )}
              <div>
                <h3 className="text-2xl font-bold text-gray-800 text-center font-display3">
                  {pkg.name}
                </h3>
                <p className="mt-3 text-purple-500 text-3xl font-bold text-center font-display3">
                  {pkg.price}
                </p>
                <ul className="mt-6 space-y-2 text-cyan-900 text-sm text-center font-display3 font-bold">
                  {pkg.features.map((feature, i) => (
                    <li key={i}>{feature}</li>
                  ))}
                </ul>
              </div>
              <motion.button
                whileHover={{ scale: 1.1 }}
                className="mt-6 w-full py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition"
                onClick={() => handleTeklifAl(pkg.name)}
              >
                Hemen İletişime Geç
              </motion.button>
            </motion.div>
          ))}
        </div>

        {showForm && (
          <Form selectedPackage={selectedPackage} setShowForm={setShowForm} />
        )}
      </section>
    </div>
  );
};

export default PricingTable;
