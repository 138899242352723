import React, { useState } from "react";
import Footer from "./Footer";
import Form from "./Form";
import HamburgerMenu from "./HamburgerMenu";

const About = () => {

    const [showForm, setShowForm] = useState(false);
  
    const handleTeklifAl = () => {
      setShowForm(true);
    };


  return (
    <div className="w-full">
      
      {/* Hero Section */}
      <section className="text-center py-12 bg-white relative"> <div className="absolute md:-top-8 -top-2 left-6 ">
        <img
          src="https://res.cloudinary.com/dd4d48hwn/image/upload/v1729154751/Ads%C4%B1z_Logo_1_qpt2q0.png"
          alt="Logo"
          className="w-32 h-auto md:w-48 "
        />
      </div>
        <div className="container mx-auto mt-12 md:mt-0">
          <h1 className="text-5xl font-bold text-gray-900 font-display4 text-pink-600">
            Burası
          </h1>
          <h1 className="text-6xl font-bold text-cyan-950 font-display1">
            İnnowex Yazılım
          </h1>
          <p className="mt-4 text-2xl text-gray-500 font-display2 font-semibold">
            Markanızın ihtiyaçlarını iyi analiz ederek raporlarla
            geliştirilebilir ürünler yaratır,
            <br />
            iletişim odaklı pazarlama stratejisi ile hedef kitlenize daha yakın
            olmanızı sağlarız.
          </p>
          <div className="relative mt-10 flex justify-center">
            {/* Custom Image and Decorations */}
            <img
              src="https://res.cloudinary.com/dd4d48hwn/image/upload/v1728913588/Ads%C4%B1z_tasar%C4%B1m_11_dwqsis.png"
              alt="Office"
              className="w-full max-w-xl"
            />
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="py-16 bg-gray-50">
        <HamburgerMenu/>
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold text-gray-900 font-display4 text-pink-600">
            innowex yazılım
          </h1>
          <h2 className="text-6xl font-bold text-cyan-950 font-display1">
            Hakkımızda
          </h2>
          <p className="mt-4 text-2xl text-gray-500 font-display2  md:mx-40 mx-5 mt-6">
           2023 yılında başladığımız dijital pazarlama dünyasındaki
            yolculuğumuz bugün farklı sektörlerden birçok marka ile büyüyerek
            devam ediyor.
            <br />
            <br />
            Innowex olarak, iş birliği yaptığımız markaların dijital
            dönüşümlerini hayata geçiriyor, onları dijital geleceğe
            hazırlıyoruz.
            <br />
            <br />
            Teknolojik altyapımız ve gelişen teknolojileri yakından takip eden
            deneyimli ekibimiz ile ihtiyacınız olan dijital dönüşüm stratejinizi
            belirleyerek planlamanızı yapıyoruz.
            <br />
            <br />
            Marka kimliğinizi öne çıkartacak yenilikçi çözümler ve yaratıcı
            fikirlerimizle sonuç odaklı işler üreterek memnuniyetinizi
            garantiliyoruz.
            <br />
            İş dünyası hızla dijitalleşirken haliyle değişim de kaçınılmaz
            oluyor.
            <br />
            <br />
            Bu süreçte markanızın tüm dijital süreçlerini tek bir çatı altında
            toplayacak profesyonel bir iş ortaklığı için biz hazırız.
          </p>
          <button
         onClick={handleTeklifAl}

            className="mt-8 inline-block px-10 py-4 bg-cyan-950 text-white font-semibold rounded-lg hover:bg-pink-600 transition"
          >
            İLETİŞİME GEÇ
          </button>
          <a href="#neler-yapiyoruz" className="relative group inline-block md:ml-8 ">
            <span className="relative z-10 text-black font-semibold text-xl font-display3">
              HİZMETLERİMİZ
            </span>

            <svg
              className="absolute -top-4 left-4 w-32 h-16"
              viewBox="0 0 120 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 30 C 30 10, 90 10, 100 30 C 90 50, 30 50, 20 30 Z"
                stroke="pink"
                strokeWidth="3"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="transition-colors duration-300 ease-in-out group-hover:stroke-purple-600"
              />

              <path
                d="M25 30 C 35 15, 85 15, 95 30 C 85 45, 35 45, 25 30 Z"
                stroke="pink"
                strokeWidth="3"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="transition-colors duration-300 ease-in-out group-hover:stroke-purple-600"
              />
            </svg>
          </a>
        </div>
      </section>

      {/* What We Do Section */}
      <section id="neler-yapiyoruz" className="py-16 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-6xl font-bold text-cyan-950 font-display1">Neler Yapıyoruz?</h2>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-4xl font-bold text-gray-900 font-display4 text-pink-600">
                Web Tasarım ve Yazılımı
              </h3>
              <p className="mt-4 text-2xl text-gray-500 font-display2 ">Web sitenizi size özel tasarlıyoruz.
              Marka kimliğinizi yansıtan, kullanıcı dostu ve estetik bir web sitesi sunuyoruz. Amacımız gelişen dijital dünyada,
              modern teknolojilerle sizinde yerinizi almanız.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-4xl font-bold text-gray-900 font-display4 text-pink-600">
                SEO Optimizasyonu
              </h3>
              <p className="mt-4 text-2xl text-gray-500 font-display2 ">Web site optimizasyonu, anahtar kelime ve 
                rekabet analizi, özgün içerik yazımı ile birlikte SEO trafiğinizi yükseltiyor, dönüşüm oranlarınızı kalıcı olarak artırıyoruz.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-4xl font-bold text-gray-900 font-display4 text-pink-600">
                Google Reklam Yönetimi
              </h3>
              <p className="mt-4 text-2xl text-gray-500 font-display2  ">Google ve Meta partner ajansı olarak, dijital dünyadaki bilinirliğinizi, etkileşim ve dönüşüm
                 oranlarınızı optimize edilmiş bütçelerle hedefleyip planlıyoruz.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Statistics Section */}
      {/* <section className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <div className="flex justify-around">
            <div>
              <h3 className="text-3xl font-bold text-red-500">+18</h3>
              <p className="text-gray-600">Yıllık Tecrübe</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold text-red-500">+450</h3>
              <p className="text-gray-600">Yönetilen Marka</p>
            </div>
            <div>
              <h3 className="text-3xl font-bold text-red-500">+1000</h3>
              <p className="text-gray-600">Tamamlanan Proje</p>
            </div>
          </div>
        </div>
      </section> */}

      {/* Recent Projects Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto">
          {/* <h2 className="text-3xl font-bold text-gray-900 text-center">
            Son Dönem Çalışmalarımız
          </h2> */}
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* <div className="bg-white p-4 rounded-lg shadow-lg">
              <img
                src="project1.jpg"
                alt="Project 1"
                className="w-full rounded-md"
              />
              <h3 className="mt-4 text-lg font-semibold text-gray-800">
                Hilton Garden Inn
              </h3>
              <p className="text-gray-600">Yaz Kampanyası</p>
            </div> */}
            {/* <div className="bg-white p-4 rounded-lg shadow-lg">
              <img
                src="project2.jpg"
                alt="Project 2"
                className="w-full rounded-md"
              />
              <h3 className="mt-4 text-lg font-semibold text-gray-800">TSPB</h3>
              <p className="text-gray-600">Yatırımcı Buluşmaları</p>
            </div> */}
            {/* Add more project cards */}
          </div>
        </div>
        {showForm && (
        <Form setShowForm={setShowForm} />
      )}
      </section>
      <Footer />
    </div>
  );
};

export default About;
