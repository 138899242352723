import React, { useState } from "react";
import { motion } from "framer-motion";
import Form from "./Form";

const PricingTable = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState("");

  const handleTeklifAl = (pkgName) => {
    setSelectedPackage(pkgName);
    setShowForm(true);
  };

  const packages = [
    {
      name: "Düşük Rekabetli SEO Paketi",
      price: "5.000₺",
      features: [
        "Rekabet Oranı Düşük Siteler İçin",
        "Hata Ayıklama ve Düzeltme",
        "Site Hızı Çalışmaları",
        "Genel SEO Stratejileri",
        "Tüm Anahtar Kelimelere Çalışma",
        "Rakip Analizi",
        "İçerik Analizi",
        "İç SEO Ayarları",
        "Site İçi İçerik Paylaşımları",
        "Site İçi Link İnşaası",
        "Kırık Link Kontrolü",
        "Backlink Çalışmaları",
        "Blog Çalışmaları",
        "Çeşitli Tanıtımlar",
        "Peşin Fiyatına 3 Taksit",
        "Çalışma Sonu Detaylı Raporlama",
        "Aylık Fiyat",
      ],
    },
    {
      name: "Orta Rekabetli SEO Paketi",
      price: "7.500₺",
      popular: true,
      features: [
        "Rekabet Oranı Orta Seviye Siteler İçin",
        "Hata Ayıklama ve Düzeltme",
        "Site Hızı Çalışmaları",
        "Genel SEO Stratejileri",
        "Tüm Anahtar Kelimelere Çalışma",
        "Rakip Analizi",
        "İçerik Analizi",
        "İç SEO Ayarları",
        "Site İçi İçerik Paylaşımları",
        "Site İçi Link İnşaası",
        "Kırık Link Kontrolü",
        "Backlink Çalışmaları",
        "Blog Çalışmaları",
        "Çeşitli Tanıtımlar",
        "Peşin Fiyatına 3 Taksit",
        "Her Ay Detaylı Raporlama",
        "Aylık Fiyat",
      ],
    },
    {
      name: "Yüksek Rekabetli SEO Paketi",
      price: "10.000₺",
      features: [
        "Rekabet Oranı Yüksek Siteler İçin",
        "Hata Ayıklama ve Düzeltme",
        "Site Hızı Çalışmaları",
        "Genel SEO Stratejileri",
        "Tüm Anahtar Kelimelere Çalışma",
        "Rakip Analizi",
        "İçerik Analizi",
        "İç SEO Ayarları",
        "Site İçi İçerik Paylaşımları",
        "Site İçi Link İnşaası",
        "Kırık Link Kontrolü",
        "Backlink Çalışmaları",
        "Blog Çalışmaları",
        "Çeşitli Tanıtımlar",
        "Peşin Fiyatına 3 Taksit",
        "Çalışma Sonu Detaylı Raporlama",
        "Aylık Fiyat",
      ],
    },
  ];

  return (
    <section className="py-12 bg-white font-display3">
      <div className="relative py-6 bg-white flex flex-col items-center justify-center text-white text-center px-4">
      {/* Main Heading */}
      <h1 className="text-5xl sm:text-8xl font-bold ">
        <span className="text-pink-700">SEO Stratejileri ile</span> <span className="text-blue-400">Arama Motorlarında </span><span className="text-lime-400"> Zirveye </span>{' '}
        <span className="text-lime-500">Çıkın!</span>.
      </h1>
      
      {/* Subtext */}
      <p className="mt-4 text-base sm:text-lg text-gray-300 mt-6">
       <span className="text-black">Web sitenizi arama motorları için optimize ediyor, organik trafiğinizi artırıyoruz.</span> 
      </p>
      </div>
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-purple-600 font-display3 mt-8 mb-5">
          SEO Hizmeti
        </h2>
        <p className="mt-2 text-gray-600 font-display3 mb-8">
          Profesyonel SEO Hizmetimiz ile kalıcı ve hızlı şekilde yükselin.
        </p>
      </div>
      <div className="relative max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4 sm:px-6 lg:px-8 mb-8">
        {packages.map((pkg, index) => (
          <motion.div
            key={index}
            className={`relative p-10 rounded-lg transition-all duration-300 ease-in-out border flex flex-col justify-between ${
              pkg.popular
                ? "border-purple-500 bg-white shadow-xl"
                : "border-gray-200"
            }`}
            style={{
              boxShadow: pkg.popular
                ? "0 12px 40px rgba(0, 0, 0, 0.3)"
                : "0 8px 25px rgba(0, 0, 0, 0.2)",
            }}
            whileHover={{
              boxShadow: "0 12px 40px rgba(0, 0, 0, 0.3)",
            }}
          >
            {pkg.popular && (
              <span className="bg-purple-600 text-white text-sm px-3 py-1 rounded-full absolute top-0 -translate-y-4 left-4  shadow-2xl">
                Popüler
              </span>
            )}
            <div>
              <h3 className="text-2xl font-bold text-gray-800 text-center font-display3">
                {pkg.name}
              </h3>
              <p className="mt-3 text-purple-500 text-3xl font-bold text-center font-display3">
                {pkg.price}
              </p>
              <ul className="mt-6 space-y-2 text-cyan-900 text-sm text-center font-display3 font-bold">
                {pkg.features.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
            </div>
            <motion.button
              whileHover={{ scale: 1.1 }}
              className="mt-6 w-full py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition"
              onClick={() => handleTeklifAl(pkg.name)}
            >
              Hemen İletişime Geç
            </motion.button>
          </motion.div>
        ))}
      </div>

      {showForm && (
         <Form selectedPackage={selectedPackage} setShowForm={setShowForm} />
      )}
    </section>
  );
};

export default PricingTable;
