// HamburgerMenu.js
import React, { useState } from "react";
import { motion } from "framer-motion";
import { IoCloseOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";

export default function HamburgerMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const menuItems = [ 
    { name: "ANA SAYFA", link: "/" },
    { name: "HAKKIMIZDA", link: "/hakkimizda" },
    { name: "HİZMETLERİMİZ", link: "/hizmetlerimiz" },
    { name: "İŞLERİMİZ", link: "#" },
    { name: "MÜŞTERİLERİMİZ", link: "#" },
    { name: "BLOG", link: "#" },
    { name: "ONLINE", link: "#" },
   
  ];

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {/* Hamburger Icon */}
      <motion.button
        className="absolute top-8 right-6 z-50 text-black text-5xl md:text-6xl"
        onClick={handleMenuToggle}
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.3 }}
      >
        {menuOpen ? (
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: 90 }}
            transition={{ duration: 0.5 }}
          >
            <IoCloseOutline color="white" />
          </motion.div>
        ) : (
          <motion.div
            initial={{ rotate: 90 }}
            animate={{ rotate: 0 }}
            transition={{ duration: 0.5 }}
          >
            <RxHamburgerMenu color="black" />
          </motion.div>
        )}
      </motion.button>

      {/* Fullscreen Menu */}
      <motion.div
        className={`fixed inset-0 z-40 transition-transform duration-700 ease-in-out ${
          menuOpen ? "translate-x-0" : "translate-x-full"
        } flex flex-col md:flex-row`}
        initial={{ x: "100%" }}
        animate={{ x: menuOpen ? 0 : "100%" }}
        transition={{ duration: 0.7, ease: "easeInOut" }}
      >
        {/* Right Section */}
        <div className="w-full md:w-1/2 flex justify-center items-center ">
          <img
            className="w-full h-full object-cover hidden md:block"
            src="https://res.cloudinary.com/dd4d48hwn/image/upload/v1727689444/Siyah_Ye%C5%9Fil_Modern_Yapay_Zeka_Instagram_G%C3%B6nderisi_1_e9dzzr.png"
            alt="Background"
          />
        </div>

        {/* Left Section - Hamburger Menu */}
        <div className="w-full md:w-1/2 hamburger-color flex justify-center items-center p-8 h-screen">
          <ul className="space-y-8 text-white text-3xl md:text-5xl font-display3 text-center md:text-right md:pr-32">
            {menuItems.map((item, index) => (
              <li
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                className="relative cursor-pointer"
              >
                <motion.a
                  href={item.link}
                  initial={{ color: "#FFFFFF" }}
                  animate={{
                    color: hoveredIndex === index ? "#FFB703" : "#FFFFFF",
                  }}
                  transition={{ duration: 0.4 }}
                  className="no-underline"
                >
                  {item.name}
                </motion.a>
                {hoveredIndex === index && (
                  <motion.div
                    className="absolute top-1/2 right-0 transform -translate-y-1/2 h-0.5 bg-yellow-400"
                    style={{ right: "-100vw" }}
                    initial={{ width: 0 }}
                    animate={{ width: "100vw" }}
                    transition={{ duration: 0.7, ease: "easeInOut" }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </motion.div>
    </>
  );
}
