import { FaCheckCircle, FaWineGlass, FaRocket, FaThumbsUp, FaSmile, FaWrench } from 'react-icons/fa';
import { motion } from 'framer-motion';
import bggif from "../assets/images/4.mp4";

const reasons = [
  {
    icon: <FaCheckCircle size={50} />, // İkon boyutunu küçülttük
    title: 'Güçlü Alt Yapı',
    description: 'Uzman ekiplerimizle tüm alanlarda güçlü bir altyapı kurduk.',
  },
  {
    icon: <FaWineGlass size={50} />,
    title: 'Modern Teknoloji',
    description: '2024 yılında en ileri teknolojiyi kullanarak kaliteli hizmet sunuyoruz.',
  },
  {
    icon: <FaRocket size={50} />,
    title: 'Genç ve Dinamik Ekip',
    description: 'Profesyonel ekip arkadaşlarımızla sizlere en iyi hizmeti veriyoruz.',
  },
  {
    icon: <FaThumbsUp size={50} />,
    title: 'Ekonomik Fiyatlar',
    description: 'Kaliteli hizmeti uygun fiyatlarla sunuyoruz.',
  },
  {
    icon: <FaSmile size={50} />,
    title: '%100 Müşteri Memnuniyeti',
    description: 'Her çalışmamızda müşteri memnuniyetini ön planda tutuyoruz.',
  },
  {
    icon: <FaWrench size={50} />,
    title: 'Sorunsuz Hizmet',
    description: 'Kuruluşumuzdan bu yana sorunsuz hizmet sunuyoruz.',
  },
];

const NedenBiziSecmelisiniz = () => {
  return (
    <div className="relative">
      {/* Video arka plan */}
      <video 
        src={bggif} 
        autoPlay 
        loop 
        muted 
        className="absolute top-0 left-0 w-full h-full object-cover z-[-1] hidden md:block"
      />

      {/* Opak arka plan katmanı */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-black to-transparent opacity-80 z-[-1]" />

      <motion.section
        className="relative py-16 px-4 lg:px-24 text-white" // Daha geniş padding ve temiz bir arka plan
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-center text-4xl font-semibold mb-16 font-display3"> {/* Daha sade ve minimal başlık */}
          Neden Bizi Tercih Etmelisiniz?
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12"> {/* Daha büyük boşluklar */}
          {reasons.map((reason, index) => (
            <motion.div
              key={index}
              className="text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <div className="mb-6 p-6 rounded-full bg-gray-800 inline-block shadow-lg hover:bg-teal-600 transition-colors duration-300">
                <div className="text-teal-500 hover:text-white transition-colors duration-300">
                  {reason.icon}
                </div>
              </div>
              <h3 className="text-2xl font-medium mb-4 font-display3">{reason.title}</h3>
              <p className="md:text-gray-300 max-w-sm mx-auto text-gray-700 font-display3">{reason.description}</p> {/* Açıklamaları daha profesyonel hale getirdik */}
            </motion.div>
          ))}
        </div>
      </motion.section>
    </div>
  );
};

export default NedenBiziSecmelisiniz;
