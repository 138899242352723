import { motion } from "framer-motion";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
  FaGithub,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-br from-pink-500 via-purple-600 to-blue-600 text-white py-16">
      <div className="border-b border-gray-400 mb-4">
        <div className="container mx-auto px-6 flex flex-col items-center justify-between">
          <div className="text-3xl font-display3 text-center">
            Ürün ve hizmetlerimiz hakkında daha detaylı bilgi almak için hemen
            arayın.
          </div>
          <br/>
          <br/>
          <br/>
          <div className="mb-10 text-3xl font-display3 fotn-semibold hover:text-gray-300">0507 911 04 66</div>
        </div>
      </div>
      {/* Üst Bölüm */}
      <div className="container mx-auto px-6 grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Hakkımızda */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          className="flex flex-col"
        >
          <h2 className="text-3xl font-extrabold mb-4">Hakkımızda</h2>
          <p className="text-gray-100 text-sm leading-relaxed">
            Yazılım dünyasında yenilikçi ve ilham verici çözümler sunan bir
            firmayız. Amacımız, en ileri teknolojilerle müşterilerimize özel
            yazılım hizmetleri sunmaktır.
          </p>
        </motion.div>

        {/* Hizmetlerimiz */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.2 }}
        >
          <h2 className="text-3xl font-extrabold mb-4">Hizmetlerimiz</h2>
          <ul className="text-gray-100 text-sm space-y-4">
            <li>
              <a href="#" className="hover:text-yellow-400">
                Web Geliştirme
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-yellow-400">
                Mobil Uygulama
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-yellow-400">
                Bulut Çözümleri
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-yellow-400">
                Yapay Zeka
              </a>
            </li>
          </ul>
        </motion.div>

        {/* Projelerimiz */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.4 }}
        >
          <h2 className="text-3xl font-extrabold mb-4">Projelerimiz</h2>
          <ul className="text-gray-100 text-sm space-y-4">
            <li>
              <a href="#" className="hover:text-yellow-400">
                E-Ticaret Çözümleri
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-yellow-400">
                Kurumsal Web Siteleri
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-yellow-400">
                Finansal Yazılımlar
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-yellow-400">
                Veri Analizi
              </a>
            </li>
          </ul>
        </motion.div>

        {/* İletişim */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.6 }}
        >
          <h2 className="text-3xl font-extrabold mb-4">İletişim</h2>
          <p className="text-gray-100 text-sm">
            Mevlana Mah. Bademli Cad. Bademli Apt. No:20 Talas / Kayseri
          </p>
          <p className="text-gray-100 text-sm">Email: info@innowex.com.tr</p>
          <p className="text-gray-100 text-sm">Telefon: +90 507 911 0466</p>
        </motion.div>
      </div>

      {/* İkonlar ve Sosyal Medya */}
      <div className="border-t border-gray-400 mt-12 pt-6">
        <div className="container mx-auto px-6 flex flex-col md:flex-row items-center justify-between">
          <p className="text-gray-200 text-sm">
            &copy; {new Date().getFullYear()} INNOWEX SOFTWARE AND DIGITAL
            AGENCY. Tüm Hakları Saklıdır.
          </p>
          <div className="flex space-x-6 mt-6 md:mt-0">
            <motion.a
              whileHover={{ scale: 1.4, rotate: 10 }}
              whileTap={{ scale: 0.9 }}
              className="text-white hover:text-pink-400 transition"
              href="https://facebook.com"
            >
              <FaFacebookF size={28} />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.4, rotate: 10 }}
              whileTap={{ scale: 0.9 }}
              className="text-white hover:text-blue-300 transition"
              href="https://twitter.com"
            >
              <FaTwitter size={28} />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.4, rotate: 10 }}
              whileTap={{ scale: 0.9 }}
              className="text-white hover:text-blue-600 transition"
              href="https://linkedin.com"
            >
              <FaLinkedinIn size={28} />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.4, rotate: 10 }}
              whileTap={{ scale: 0.9 }}
              className="text-white hover:text-pink-600 transition"
              href="https://instagram.com"
            >
              <FaInstagram size={28} />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.4, rotate: 10 }}
              whileTap={{ scale: 0.9 }}
              className="text-white hover:text-red-600 transition"
              href="https://youtube.com"
            >
              <FaYoutube size={28} />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.4, rotate: 10 }}
              whileTap={{ scale: 0.9 }}
              className="text-white hover:text-gray-400 transition"
              href="https://github.com"
            >
              <FaGithub size={28} />
            </motion.a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
