import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { FaAngleRight } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoCloseOutline } from "react-icons/io5";

export default function HeroSection() {
  const sliderRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const contentRef = useRef(null); // İçeriği referans almak için

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToContent = () => {
    contentRef.current.scrollIntoView({ behavior: "smooth" }); // İçeriğe yumuşak kaydırma
  };

  const menuItems = [
    { name: "HAKKIMIZDA", link: "/hakkimizda" }, // Only this one has a valid URL for now
    { name: "HİZMETLERİMİZ", link: "/hizmetlerimiz" },
    { name: "İŞLERİMİZ", link: "#" },
    { name: "MÜŞTERİLERİMİZ", link: "#" },
    { name: "BLOG", link: "#" },
    { name: "ONLINE", link: "#" },
    { name: "KATALOG", link: "#" },
  ];

  // KAYAN YAZI
  const [text, setText] = useState("");
  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopIndex, setLoopIndex] = useState(0); // Hangi kelimenin sırada olduğunu tutar
  const [speed, setSpeed] = useState(25); // Yazma/silme hızı

  // Değişecek olan kelimeler
  const changingWords = ["Kolaylaştırdık!", "Dönüştürdük!", "Geliştirdik!"];

  useEffect(() => {
    const handleTyping = () => {
      const currentWord = changingWords[loopIndex]; // Şu anki kelime
      const updatedText = isDeleting
        ? currentWord.substring(0, text.length - 1) // Silinme efekti
        : currentWord.substring(0, text.length + 1); // Yazılma efekti

      setText(updatedText);

      // Hız değiştirme mantığı
      if (!isDeleting && updatedText === currentWord) {
        setTimeout(() => setIsDeleting(true), 2000); // 2 saniye bekle sonra sil
        setSpeed(100); // Silme daha hızlı olsun
      } else if (isDeleting && updatedText === "") {
        setIsDeleting(false); // Silme tamamlanınca yeni kelimeye geç
        setLoopIndex((prevIndex) => (prevIndex + 1) % changingWords.length); // Bir sonraki kelimeye geç
        setSpeed(200); // Yazma hızına geri dön
      }
    };

    const typingTimeout = setTimeout(handleTyping, speed);

    return () => clearTimeout(typingTimeout); // Cleanup
  }, [text, isDeleting, loopIndex, speed]);

  // KAYAN YAZI

  return (
    <div className="hero-section relative bg-yellow-300 min-h-screen flex flex-col">
      {/* Logo Image in Top Left */}
      <div className="absolute md:-top-8 -top-3  left-4 ">
        <img
          src="https://res.cloudinary.com/dd4d48hwn/image/upload/v1729154751/Ads%C4%B1z_Logo_1_qpt2q0.png"
          alt="Logo"
          className="w-32 h-auto md:w-48"
        />
      </div>

      {/* Hamburger Icon */}
      <motion.button
        className="absolute top-7 right-6 z-50 text-black text-5xl md:text-6xl"
        onClick={handleMenuToggle}
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.3 }}
      >
        {menuOpen ? (
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: 90 }}
            transition={{ duration: 0.5 }}
          >
            <IoCloseOutline color="white" />
          </motion.div>
        ) : (
          <motion.div
            initial={{ rotate: 90 }}
            animate={{ rotate: 0 }}
            transition={{ duration: 0.5 }}
          >
            <RxHamburgerMenu color="black" />
          </motion.div>
        )}
      </motion.button>

      {/* Fullscreen Menu */}
      <motion.div
        className={`fixed inset-0 z-40 transition-transform duration-700 ease-in-out  ${
          menuOpen ? "translate-x-0" : "translate-x-full "
        } flex flex-col md:flex-row`}
        initial={{ x: "100%" }}
        animate={{ x: menuOpen ? 0 : "100%" }}
        transition={{ duration: 0.7, ease: "easeInOut" }}
      >
        <div className="absolute hidden md:block -top-8 left-6 ">
          <img
            src="https://res.cloudinary.com/dd4d48hwn/image/upload/v1729153785/Ads%C4%B1z_Logo_bn6xkm.png"
            alt="Logo"
            className="w-32 h-auto md:w-48"
          />
        </div>
        {/* Right Section */}
        <div className="w-full md:w-1/2 bg-cyan-900 flex justify-center items-center">
          <img
            className="w-full h-full object-cover hidden md:block"
            src="https://res.cloudinary.com/dd4d48hwn/image/upload/v1729159502/Siyah_Ye%C5%9Fil_Modern_Yapay_Zeka_Instagram_G%C3%B6nderisi_1_e9dzzr_ky7zwy.png"
            alt="Background"
          />
        </div>

        {/* Left Section - Hamburger Menu */}
        <div className="w-full md:w-1/2 bg-gray-900 flex justify-center items-center p-8 h-screen hamburger-color">
          <ul className="space-y-8 text-white text-3xl md:text-5xl font-display3 text-center md:text-right md:pr-32">
            {menuItems.map((item, index) => (
              <li
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                className="relative cursor-pointer"
              >
                <motion.a
                  href={item.link}
                  initial={{ color: "#FFFFFF" }}
                  animate={{
                    color: hoveredIndex === index ? "#FFB703" : "#FFFFFF",
                  }}
                  transition={{ duration: 0.4 }}
                  className="no-underline"
                >
                  {item.name}
                </motion.a>
                {hoveredIndex === index && (
                  <motion.div
                    className="absolute top-1/2 right-0 transform -translate-y-1/2 h-0.5 bg-yellow-400"
                    style={{ right: "-100vw" }}
                    initial={{ width: 0 }}
                    animate={{ width: "100vw" }}
                    transition={{ duration: 0.7, ease: "easeInOut" }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </motion.div>

      {/* Content Section */}
      <div
        ref={contentRef}
        className="flex flex-col-reverse md:flex-row items-center h-full "
      >
        <div className="flex-1 p-8 -translate-y-32 md:-translate-y-0">
          <h1 className="text-4xl md:text-6xl font-bold text-black mb-4">
            Teknolojiyi <span className="text-blue-400">Basitleştirip,</span>
            <span className="text-lime-500">İşinizi </span>
            <br />
            <span className="inline-block text-pink-600">{text}</span>
          </h1>
         <div className="mt-10"><motion.a
            href="/hizmetlerimiz"
            className="px-6 py-3 bg-black text-white rounded-lg "
            whileHover={{
              scale: 1.1,
              backgroundColor: "#333",
              transition: { duration: 0.3 },
            }}
            whileTap={{ scale: 0.9 }}
          >
            Çözümlerimizi Keşfedin
          </motion.a></div> 
        </div>

        {/* Image Section */}
        <div className="flex-1 mt-12 md:mt-0 hidden md:block">
          <motion.img
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            src="https://res.cloudinary.com/dd4d48hwn/image/upload/v1728044258/bgbgbg_clvcxn.png"
            alt="Person with Laptop"
            className="max-w-full h-auto"
          />
        </div>
        <div className="flex-1 mt-12 md:mt-0 md:hidden">
          <motion.img
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            src="https://res.cloudinary.com/dd4d48hwn/image/upload/v1728125196/Dark_Purple_Blue_Abstract_Echoes_of_Infinity_Album_Cover_2_ndhvtf.png"
            alt="Person with Laptop"
            className="w-[500px] h-auto"
          />
        </div>
      </div>

      {/* S-Shaped Wave Positioned at Bottom */}
      <div className="absolute bottom-0 left-0 w-full translate-y-1 hidden md:block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 60"
          className="w-full"
        >
          <path
            fill="#facc15"
            d="M0,0L30,10C60,20,120,40,180,50C240,60,300,50,360,40C420,30,480,20,540,30C600,40,660,60,720,50C780,40,840,20,900,20C960,20,1020,40,1080,50C1140,60,1200,40,1260,30C1320,20,1380,10,1410,0L1440,0L1440,60L1410,60C1380,60,1320,60,1260,60C1200,60,1140,60,1080,60C1020,60,960,60,900,60C840,60,780,60,720,60C660,60,600,60,540,60C480,60,420,60,360,60C300,60,240,60,180,60C120,60,60,60,30,60L0,60Z"
          ></path>
        </svg>
      </div>
    </div>
  );
}
