import React from "react";
import HeroSection from "../component/HeroSection/HeroSection";
import ResponsiveHeroSection from "../component/HeroSection/ResponsiveHeroSection";
import WebSales from "../component/WebSales";
import SeoSales from "../component/SeoSales";
import GoogleSales from "../component/GoogleSales";
import Footer from "../component/Footer";
import WhyUs from "../component/WhyUs";

export default function Home() {
  return (
    <div>
      <HeroSection/>
      <WebSales/>
      <SeoSales/>
      <GoogleSales/>
      <WhyUs/>
      <Footer/>
    </div>
  );
}
