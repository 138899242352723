import React, { useState } from "react";
import {
  FaCogs,
  FaShoppingCart,
  FaLightbulb,
  FaMobileAlt,
  FaSearch,
  FaGoogle,
  FaAngleRight,
} from "react-icons/fa"; // Importing icons
import HamburgerMenu from "./HamburgerMenu";
import { SiBmcsoftware } from "react-icons/si";
import { IoCodeSlashSharp } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { IoCodeWorkingSharp } from "react-icons/io5";
import Footer from "./Footer";
import Form from "./Form";

const services = [
  {
    icon: <SiBmcsoftware />,
    title: "Yazılım Geliştirme",
    description:
      "Kurumsal ihtiyaçlarınıza yönelik web ve mobil yazılım çözümlerimizle yanınızdayız!",
  },
  {
    icon: <IoCodeWorkingSharp />,
    title: "Web Yazılım",
    description:
      "UI, UX tasarım çözümleri, ihtiyaçlarınıza özel altyapı önerileri ile web yazılım çözümleri sağlıyoruz.",
  },
  {
    icon: <FaMobileAlt />,
    title: "Mobil Uygulama Yazılımı",
    description:
      "İhtiyaçlarınıza uygun mobil uygulama çözümlerimizle dijital dünyada yerinizi alın!",
  },
  {
    icon: <FaCogs />,
    title: "Özel Yazılımlar",
    description:
      "Firmanızın süreçlerine uygun, tamamen size özel yazılım çözümleri sunuyoruz.",
  },
  {
    icon: <IoCodeSlashSharp />,
    title: "Web Tasarım",
    description:
      "UI, UX tasarım çözümleri, ihtiyaçlarınıza özel altyapı önerileri ile web site tasarım sürecinizi birlikte yönetelim.",
  },
  {
    icon: <IoCheckmarkDoneSharp />,
    title: "Kurumsal Web Tasarım",
    description:
      "Kurumsal web siteniz için en profesyonel ve modern tasarımları sunuyoruz.",
  },
  {
    icon: <FaShoppingCart />,
    title: "E-Ticaret Yazılım ve Çözümleri",
    description:
      "E-ticaret mağazanız için profesyonel yazılım çözümleri sunuyoruz.",
  },
  {
    icon: <FaSearch />,
    title: "Arama Motoru Optimizasyonu",
    description:
      "Web sitenizin optimizasyonu, anahtar kelime hedeflemesi ve özgün içerik üretimi. Organik trafik artışı için seo planlaması için buradayız!",
  },
  {
    icon: <FaGoogle />,
    title: "Google Ads Reklamcılığı",
    description:
      "Google partner ajansı olarak, hizmet ve ürünlerinizin reklam planlamaları için uzman ekibimizle destek için hazırız.",
  },
  {
    icon: <FaSearch />,
    title: "SEO Optimizasyonu",
    description:
      "Web sitenizin SEO optimizasyonu ile organik trafik artışınızı sağlıyoruz.",
  },
  {
    icon: <FaShoppingCart />,
    title: "E-ticaret Danışmanlığı",
    description:
      "Eticaret mağazanız istediğiniz performansı göstermiyor mu? Eticaret danışmanlık hizmetimizle tüm süreçlerinize birlikte göz atalım.",
  },
  {
    icon: <FaLightbulb />,
    title: "Strateji ve Danışmanlık",
    description:
      "Kurum içi bir dijital pazarlama ekibiniz var ve desteğe mi ihtiyacınız var? Danışmanlık hizmetimizle yanınızdayız!",
  },
];

const service = [
  "Yazılım Geliştirme",
  "Web Yazılım",
  "Mobil Uygulama Yazılımı",
  "Özel Yazılımlar",
  "Web Tasarım",
  "Kurumsal Web Tasarım",
  "E-Ticaret Yazılım ve Çözümleri",
  "Arama Motoru Optimizasyonu",
  "Google Ads Reklamcılığı",
  "SEO Optimizasyonu",
  "E-ticaret Danışmanlığı",
  "Strateji ve Danışmanlık",
];

const Services = () => {
  const [showForm, setShowForm] = useState(false);

  const handleTeklifAl = () => {
    setShowForm(true);
  };
  return (
    <section className="py-16 bg-white ">
       <div className="absolute md:-top-8 -top-2 left-6 ">
        <img
          src="https://res.cloudinary.com/dd4d48hwn/image/upload/v1729154751/Ads%C4%B1z_Logo_1_qpt2q0.png"
          alt="Logo"
          className="w-32 h-auto md:w-48"
        />
      </div>
      <div className="md:mb-0 mb-6"><HamburgerMenu  /></div>
      {/* Header Section */}
      <div className="text-center mb-16 md:px-6 md:mt-0 mt-12">
        <h2 className="md:text-6xl text-5xl font-bold text-cyan-950 font-display1">
          Hizmetlerimiz
        </h2>
        <p className="text-5xl font-bold text-gray-900 font-display4 text-pink-600 mt-7">
          Birlikte büyümek için hedef odaklı dijital pazarlama.
        </p>
        <p className="text-xl text-gray-400 md:mx-40 mx:3 font-display3 text-gray-500 mt-7 fondemibold">
          Markanızın ihtiyaçlarını iyi analiz ederek raporlarla geliştirilebilir
          ürünler yaratır, iletişim odaklı pazarlama stratejisi ile hedef
          kitlenize daha yakın olmanızı sağlarız.
        </p>
      </div>
      <section className="py-10 bg-white">
        {/* Services Grid */}
        <div className="md:mx-48 px-2 flex justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {service.map((servicess, index) => (
              <div
                key={index}
                className="flex items-center group hover:cursor-pointer transition-all duration-300"
              >
                {/* Icon */}
                <FaAngleRight className="text-lg text-gray-900 mr-3 transition-all duration-300 group-hover:text-pink-600" />
                {/* Service Text */}
                <span className="text-lg font-medium text-gray-900 transition-all duration-300 group-hover:translate-x-2">
                  {servicess}
                </span>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-12 ">
        <div className=" md:mx-24 mx-7">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-6">
            {services.map((service, index) => (
              <div key={index}>
                <div className="flex  flex-col space-x-4 py-8">
                  <div className="text-pink-600 text-6xl ml-5">
                    {service.icon}
                  </div>
                  <div>
                    <h3 className="text-3xl font-bold text-cyan-950 mb-2 font-display3 mt-2">
                      {service.title}
                    </h3>
                    <p className="text-lg  font-display3 text-gray-400 font-semibold">
                      {service.description}
                    </p>
                  </div>
                </div>
                <a
                  href="#neler-yapiyoruz"
                  className="relative group inline-block md:ml-8  mt-6"
                >
                  <span className="relative z-10 text-cyan-950 font-display3 text-md font-display3 font-semibold">
                    DAHA FAZLASI
                  </span>
                  <div className="divide w-full h-1 shadow-xl" />

                  <svg
                    className="absolute -top-4 w-32 h-16"
                    viewBox="0 0 120 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 30 C 30 10, 90 10, 100 30 C 90 50, 30 50, 20 30 Z"
                      stroke="pink"
                      strokeWidth="3"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="transition-colors duration-300 ease-in-out group-hover:stroke-purple-600"
                    />

                    <path
                      d="M25 30 C 35 15, 85 15, 95 30 C 85 45, 35 45, 25 30 Z"
                      stroke="pink"
                      strokeWidth="3"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="transition-colors duration-300 ease-in-out group-hover:stroke-purple-600"
                    />
                  </svg>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer Button */}
      <div className="mt-16 text-center">
        <button
          onClick={handleTeklifAl}
          className="mb-8 inline-block px-10 py-4 bg-cyan-950 text-white font-semibold rounded-lg hover:bg-pink-600 transition"
        >
          İLETİŞİME GEÇ
        </button>
      </div>
      {showForm && <Form setShowForm={setShowForm} />}
      <Footer />
    </section>
  );
};

export default Services;
